<template>
  <div class="page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">网上展览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/list' }">展览管理</el-breadcrumb-item>
      <el-breadcrumb-item>{{exhibitionTitle}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id }">内容设置</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/exhibition/content/' + this.$route.params.m_id + '/exhibitior'}">参展商管理</el-breadcrumb-item>
      <el-breadcrumb-item>编辑参展商</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form class="middle-form" ref="pageRef" :model="pageForm" label-width="120px">
        <el-form-item label="归属展馆" prop="exhibitonhall">
          <el-select v-model="pageForm.exhibitonhall" filterable placeholder="请选择">
            <el-option v-for="item in zgList" :key="item.id" :label="item.title" :value="item.m_id">{{item.title}}</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="归属展团" prop="pavilion">
          <el-select v-model="pageForm.pavilion" filterable placeholder="请选择">
            <el-option v-for="item in ztList" :key="item.id" :label="item.title" :value="item.m_id"></el-option>
            <el-option label="无" value=""></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="参展商标题" prop="title">
          <el-input v-model="pageForm.title" placeholder="请输入参展商标题"></el-input>
        </el-form-item>
        <el-form-item label="参展商缩略图" prop="thumbnail">
          <upload :url="pageForm.thumbnail" :filed="'thumbnail'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="内页滚动图" prop="banner">
          <upload :urlArr="pageForm.banner" :filed="'banner'" :max-num="5" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="参展商Logo" prop="logo">
          <upload :url="pageForm.logo" :filed="'logo'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="参展商描述" prop="desc">
          <QuillBar v-model="pageForm.desc" :contenttext="pageForm.desc" @inputChange="itemChange($event)"></QuillBar>
        </el-form-item>
        <el-form-item label="展位号" prop="booth">
          <el-input v-model="pageForm.booth" placeholder="请输入参展商展位号"></el-input>
        </el-form-item>
        <el-form-item label="公司电话" prop="phone">
          <el-input v-model="pageForm.phone" placeholder="请输入公司电话"></el-input>
        </el-form-item>
        <el-form-item label="公司地址" prop="address">
          <el-input v-model="pageForm.address" placeholder="请输入公司地址"></el-input>
        </el-form-item>
        <el-form-item label="公司联系人" prop="contacts">
          <el-input v-model="pageForm.contacts" placeholder="请输入公司联系人"></el-input>
        </el-form-item>
        <el-form-item label="VR链接" prop="vr">
          <el-input v-model="pageForm.vr" placeholder="请输入参展商vr链接"></el-input>
        </el-form-item>
        <el-form-item label="合作洽谈链接" prop="chat">
          <el-input v-model="pageForm.chat" placeholder="请输入参展商合作洽谈链接"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">更 新</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/common/Upload'
import QuillBar from '@/components/common/QuillBar'
export default {
  name: 'Edit',
  components: {
    Upload,
    QuillBar
  },
  data () {
    return {
      exhibitionTitle: '',
      pageForm: {
        m_id: this.$route.params.item_mid,
        sort: 0,
        exhibitonhall: '',
        pavilion: '',
        title: '',
        thumbnail: '',
        banner: [],
        logo: '',
        desc: '',
        booth: '',
        phone: '',
        address: '',
        contacts: '',
        vr: '',
        chat: ''
      },
      pageRules: {
        exhibitonhall: [
          { required: true, message: '请选择归属展馆', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '请填写参展商标题', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        logo: [
          { required: true, message: '请上传参展商logo', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入公司电话', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入公司地址', trigger: 'blur' }
        ],
        contacts: [
          { required: true, message: '请输入公司联系人', trigger: 'blur' }
        ]
      },
      zgList: [],
      ztList: []
    }
  },
  created () {
    this.getPageData()
    this.getExhibitionTitle()
    this.getZgList()
    this.getZtList()
  },
  methods: {
    // 获取展馆列表
    async getZgList () {
      const { data: res } = await this.$http.get('/exhibition-exhibitionhall-list', { params: { m_id: this.$route.params.m_id } })
      if (res.status === 200) {
        this.zgList = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取展团列表
    async getZtList () {
      const { data: res } = await this.$http.get('/exhibition-pavilion-list', { params: { m_id: this.$route.params.m_id } })
      if (res.status === 200) {
        this.ztList = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    async getPageData () {
      const { data: res } = await this.$http.get('/exhibition-exhibitor', { params: { m_id: this.$route.params.item_mid } })
      if (res.status === 200) {
        this.pageForm.m_id = res.data.m_id
        this.pageForm.sort = res.data.sort
        this.pageForm.exhibitonhall = res.data.exhibitonhall
        this.pageForm.pavilion = res.data.pavilion
        this.pageForm.title = res.data.title
        this.pageForm.thumbnail = res.data.thumbnail
        this.pageForm.banner = res.data.banner
        this.pageForm.logo = res.data.logo
        this.pageForm.desc = res.data.desc
        this.pageForm.booth = res.data.booth
        this.pageForm.phone = res.data.phone
        this.pageForm.address = res.data.address
        this.pageForm.contacts = res.data.contacts
        this.pageForm.vr = res.data.vr
        this.pageForm.chat = res.data.chat
      } else {
        this.$message.error(res.msg)
      }
    },
    onSubmit () {
      this.$refs.pageRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/exhibition-exhibitor', this.pageForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.pageRef.resetFields()
            await this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/exhibitor')
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    cancel () {
      this.$router.push('/admin/apps/exhibition/content/' + this.$route.params.m_id + '/exhibitor')
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.pageForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.pageForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.pageForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.pageForm[field].splice(checkedIndex, 1)
      }
    },
    getExhibitionTitle () {
      this.exhibitionTitle = window.sessionStorage.getItem('ex_name')
    },
    itemChange (val) {
      this.pageForm.desc = val
    }
  }
}
</script>

<style scoped>
.middle-form{
  width: 600px;
  margin-left: auto;
  margin-right: auto;
}
/deep/.el-radio{
  margin-bottom: 14px;
}
</style>
